.materials-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid #d4d4d4;
    overflow: hidden;
  }
  
  .materials-table th,
  .materials-table td {
    border: 1px solid #d4d4d4;
    padding: 10px;
    text-align: center;
  }
  
  .materials-table th {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .materials-table td {
    background-color: rgba(0, 0, 0, 0.01);
  }
  
  .materials-table tr:nth-child(even) td {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .materials-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  